import React from "react";
import Meta from "./../components/Meta";
import CtaSection from "./../components/CtaSection";
import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";

function NotFoundPage(props) {
  return (
    <>
      <Meta title="404" />
      <Navbar bgColor="bg-white" />

      <div
        className="h-16 bg-white"
      >

      </div>
      <CtaSection
        title={
          <>
            Claim your new <span className="text-blue-600">{props.location.pathname}</span> ChurchLink
          </>
        }
        subtitle="Replace your bulletin and benefit from lower costs, easier updates and a larger audience that can access important information from anywhere"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />

      <Footer
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        sticky={true}
      />
    </>
  );
}

export default NotFoundPage;
