import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { QRCode } from 'react-qrcode-logo';
import { useAuth } from "../util/auth";
import { useOrganizationByOwner, updateOrganization } from "../util/db";

function DashboardSectionIntegrations() {
    const auth = useAuth();
    const [pending, setPending] = useState(false);
    const [formAlert, setFormAlert] = useState(null);
    const { register, handleSubmit, errors } = useForm();

    const {
    	data: organization,
  	} = useOrganizationByOwner(auth.user.uid, "organization");

    const onSubmit = (data) => {
      setPending(true);
      const formData = {...data};

      const query = updateOrganization(organization[0].id, formData);

      	query.then(() => {
			setPending(false);
		})
		.catch((error) => {
			// Hide pending indicator
			setPending(false);
			// Show error alert message
			setFormAlert({
				type: "error",
				message: error.message,
			});
		});

  	};

  	return (
    	<>
            { organization && (
                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 sm:px-6 lg:px-8">
					<div className="-mt-2 md:-mt-8 lg:-mt-12">
                        {auth.user.stripeSubscriptionId &&
                            <>
						        <h2 className="text-2xl text-slate-800">
                                    Integrations
						        </h2>
						        <p className="mt-1 text-sm leading-6 text-slate-500">
                                    Connect ChurchLink to the tools you already use
						        </p>
                            </>
                        }
                        {!auth.user.stripeSubscriptionId &&
                            <div className="mx-auto text-center">
                                <h2 className="mt-6 mb-4 text-2xl bg-gradient-to-r from-primary to-secondary inline-block text-transparent bg-clip-text">
                                    Go Pro to Unlock Integrations:
						        </h2>
                                <p className="mb-2 text-md leading-6 text-slate-700">
                                    Planning Center giving integration
						        </p>
                                <p className="mb-2 text-md leading-6 text-slate-700">
                                    Tithely giving integration
                                </p>
                                <p className="mb-8 text-md leading-6 text-slate-700">
                                    Shareable QR code
                                </p>
                                <Button
                                    href="/pricing"
                                    size="sm"
                                    isBlock={false}
                                    className="w-40 bg-indigo"
                                >
                                    Unlock now
                                </Button>
                            </div>
                        }

						{formAlert && (
							<div className="mb-4">
								<FormAlert
									type={formAlert.type}
									message={formAlert.message}
								/>
							</div>
						)}
					</div>
                    {auth.user.stripeSubscriptionId &&
                        <form className="md:col-span-2 space-y-4" onSubmit={handleSubmit(onSubmit)}>

                            <div>
                                <h2 className="text-2xl text-slate-800">
                                    Giving
                                </h2>
                                <div className="h-2"></div>
                            </div>
                            
                            <div className="grid md:grid-cols-2 gap-4">

                                <div className="flex flex-col p-4 bg-slate-50 rounded shadow-md">
                                    <h3 className="mb-4 text-lg leading-6 text-slate-800">
                                        Tithely
                                    </h3>
                                    <p className="mb-2 text-sm leading-6 text-slate-600">
                                        To locate your Tithely ID:
                                    </p>
                                    <div className="grow">
                                        <ol className="mb-8 pl-4 list-decimal text-sm leading-6 text-slate-600">
                                            <li className="mb-1">
                                                Log into Tithely as an Admin or limited user with financial permissions
                                            </li>
                                            <li className="mb-1">
                                                Navigate to Giving → Giving Form and locate your Giving Form URL
                                            </li>
                                            <li className="mb-1">
                                                If you have multiple locations select your location from the drop down
                                            </li>
                                            <li className="mb-1">
                                                Copy the numerical string from the end of the URL and add below
                                            </li>
                                        </ol>
                                    </div>
                                    <TextField
                                        label="Tithely ID"
                                        type="text"
                                        id="tithely"
                                        name="tithely"
                                        placeholder="1234567"
                                        defaultValue={organization[0] && organization[0].tithely}
                                        error={errors.tithely}
                                        inputRef={register({})}
                                    />
                                </div>
                                <div className="flex flex-col p-4 bg-slate-50 rounded shadow-md">
                                    <h3 className="mb-4 text-lg leading-6 text-slate-800">
                                        Planning Center
                                    </h3>
                                    <p className="mb-2 text-sm leading-6 text-slate-600">
                                        To locate your Planning Center ID:
                                    </p>
                                    <div className="grow">
                                        <ol className="mb-8 pl-4 list-decimal text-sm leading-6 text-slate-600">
                                            <li className="mb-1">
                                                Navigate to the Church Center tab on the Manage page in Giving
                                            </li>
                                            <li className="mb-1">
                                                Under the On The Web section, select the simple Link
                                            </li>
                                            <li className="mb-1">
                                                Copy your church name from the shareable link and add below
                                            </li>
                                        </ol>
                                    </div>
                                    <TextField
                                        label="Planning Center ID"
                                        type="text"
                                        id="planningcenter"
                                        name="planningcenter"
                                        placeholder="kcchurch"
                                        defaultValue={organization[0] && organization[0].planningcenter}
                                        error={errors.planningcenter}
                                        inputRef={register({})}
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="h-8"></div>
                                <h2 className="text-2xl text-slate-800">
                                    QR Code
                                </h2>
                                <div className="h-2"></div>
                            </div>

                            { organization && organization[0] && organization[0].permalink && 
                                <div>
                                    <QRCode logoImage={require('./../images/logo-icon-small.png')} qrStyle="dots" value={'https://churchlink.page/' + organization[0].permalink } size="250" />
                                    
                                    <p className="mt-2 text-xs text-slate-500">
									    To download, right click on the image and choose "Save Image As..."
								    </p>
                                </div>
                            }

                            <div className="pt-8 space-x-4 flex items-stretch">
                                <Button
                                    type="submit"
                                    size="md"
                                    disabled={pending}
                                    isBlock={true}
                                    className="w-20 bg-indigo-500"
                                >
                                    {!pending && <>Update</>}

                                    {pending && <LoadingIcon className="w-5" />}
                                </Button>
                            </div>

                        </form>
                    }
                </div>
            )}
    	</>
  	);
}

export default DashboardSectionIntegrations;
