import { React, useState } from "react";
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function ChurchEventSecondary(props) {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="mb-4">
            <div className={classNames(props.borderColor, 'relative py-8 border-b')}>
                <div className="relative">
                    <h2 className={classNames(props.fontFamily, props.textColor, 'pr-8 text-h1-modern cursor-pointer')} onClick={() => setIsActive(!isActive)}>
                        {props.name}
                    </h2>
                    <div className="absolute right-0 top-1/2 transform -translate-y-1/2 pointer-events-none">
                        {isActive ? <MinusIcon className={classNames(props.textColor, 'h-7 w-7')} aria-hidden="true" /> :
                            <PlusIcon className={classNames(props.textColor, 'h-7 w-7')} aria-hidden="true" />
                        }
                    </div>
                </div>
                {isActive && (
                    <>
                        <p className={classNames(props.textColor, 'mt-4 mb-2 font-sansSecondary text-h5 bold uppercase')}>
                            {props.subtitle}
                        </p>
                        <p className={classNames(props.textColor, 'mb-4 font-sansSecondary text-h4')}>
                            {props.description}
                        </p>
                        {props.url && (
                            <a href={props.url} target="_blank" rel="noreferrer" className={classNames(props.textColorAlt, 'mt-8 font-sansSecondary text-h5 bold underline')}>
                                {props.urlText ? props.urlText : "Learn More"}
                            </a>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default ChurchEventSecondary;
