import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";
import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <Navbar bgColor="bg-white" />
      <PricingSection
        title="Upgrade your ChurchLink"
        subtitle="Replace your bulletin and benefit from lower costs, easier updates and a larger audience that can access important information from anywhere"
        strapline="Pricing"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <Footer
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        sticky={true}
      />
    </>
  );
}

export default PricingPage;
