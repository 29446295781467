import { React } from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSection";
import { useRouter } from "./../util/router";
import { requireAuth } from "./../util/auth";
import DashboardNavigation from "./../components/DashboardNavigation";

function DashboardPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Dashboard" />
      
      <DashboardNavigation></DashboardNavigation>

      <main className="xl:pl-72 bg-slate-50">
        <div className="max-w-6xl mx-8 my-28 border border-slate-250">
          <DashboardSection
            title="Dashboard"
            subtitle=""
            strapline=""
            size="md"
            bgColor="bg-white"
            section={router.query.section}
            key={router.query.section}
          />
        </div>
      </main>
    </>
  );
}

export default requireAuth(DashboardPage);
