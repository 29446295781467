import React from "react";
import Section from "./Section";
import DashboardSectionChurch from "./DashboardSectionChurch";
import DashboardSectionEvents from "./DashboardSectionEvents";
import DashboardSectionDesign from "./DashboardSectionDesign";
import DashboardSectionIntegrations from "./DashboardSectionIntegrations";

function DashboardSection(props) {

  const validSections = {
    church: true,
    events: true,
    design: true,
    integrations: true,
  };
  const section = validSections[props.section] ? props.section : "church";

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      {section === "church" && <DashboardSectionChurch />}
      {section === "events" && <DashboardSectionEvents />}
      {section === "design" && <DashboardSectionDesign />}
      {section === "integrations" && <DashboardSectionIntegrations />}
    </Section>
  );
}

export default DashboardSection;
