import React from "react";
import Meta from "../components/Meta";
import SettingsSection from "../components/SettingsSection";
import DashboardNavigation from "./../components/DashboardNavigation";
import { useRouter } from "../util/router";
import { requireAuth } from "../util/auth";

function SettingsPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Settings" />

      <DashboardNavigation></DashboardNavigation>

      <main className="xl:pl-72 bg-slate-50">
        <div className="max-w-6xl mx-8 my-28 border border-slate-250">
          <SettingsSection
            size="md"
            bgColor="bg-white"
            bgImage=""
            bgImageOpacity={1}
            section={router.query.section}
            key={router.query.section}
          />
        </div>
      </main>
    </>
  );
}

export default requireAuth(SettingsPage);
