import React, { useEffect, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { ArrowLeftIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { useAuth } from "./../util/auth";
import { useItem, updateItem, createItem, uploadImage, deleteImage } from "./../util/db";

function EditItemModal(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [eventImage, setEventImage] = useState(null);
  const { register, handleSubmit, errors } = useForm();

  const [preview, setPreview] = useState();

  function handleRemoveImage(file) {
		deleteImage(file).then(() => {
			const query = updateItem(props.id, {imageURL: ""});

			query
			.then(() => {
				setPreview("");
			})
			.catch((error) => {
				setFormAlert({
					type: "error",
					message: error.message,
				});
			});
		});
	}

  useEffect(() => {
      if (!eventImage) {
          setPreview(undefined);
          return;
      }

      const objectUrl = URL.createObjectURL(eventImage)
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
  }, [eventImage]);

  const [isChecked, setIsChecked] = useState(false);
  const checkHandler = () => {
    setIsChecked(!isChecked)
  }
 
  // This will fetch item if props.id is defined
  // Otherwise query does nothing and we assume
  // we are creating a new item.
  const { data: itemData, status: itemStatus } = useItem(props.id);

  useEffect(() => {
    if(itemData && itemData.featuredEvent) {
      setIsChecked(itemData && itemData.featuredEvent);
    }
  }, [itemData]);

  // If we are updating an existing item
  // don't show modal until item data is fetched.
  if (props.id && itemStatus !== "success") {
    return null;
  }
  
  const onSubmit = (data) => {
    setPending(true);

    uploadImage(eventImage).then((url) => {

      const formData = url
      ? {...data, type: 'events', imageURL: url}
      : {...data, type: 'events'};

      const query = props.id
        ? updateItem(props.id, formData)
        : createItem({ owner: auth.user.uid, ...formData });

      query
        .then(() => {
          // Let parent know we're done so they can hide modal
          props.onDone();
        })
        .catch((error) => {
          // Hide pending indicator
          setPending(false);
          // Show error alert message
          setFormAlert({
            type: "error",
            message: error.message,
          });
        });

    }).catch((error) => {
      console.log(error);
    });

  };

  return (
    <Transition appear={true} show={true}>
      <Dialog
        as="div"
        className="overflow-y-auto fixed inset-0 z-10"
        onClose={() => props.onDone()}
      >
        <div className="px-4 min-h-screen text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {props.id ? "Update" : "Add"} Announcement
              </Dialog.Title>
              <div className="mt-4">
                {formAlert && (
                  <div className="mb-4">
                    <FormAlert
                      type={formAlert.type}
                      message={formAlert.message}
                    />
                  </div>
                )}

                <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>

                  <div className="relative flex items-start">

                    {!auth.user.stripeSubscriptionId &&
                      <>
                        <div className="absolute right-0 flex flex-row items-center">
                          <ArrowLeftIcon className="h-5 w-5 mr-1 text-primary" aria-hidden="true" />
                          <a href="/pricing" className="font-medium text-sm leading-6 bg-gradient-to-r from-primary to-secondary inline-block text-transparent bg-clip-text">
                            Go Pro to unlock
                          </a>
                        </div>

                        <div className="flex h-6 items-center">
                            <input
                              type="checkbox"
                              id="featuredEvent"
                              name="featuredEvent"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              checked={isChecked}
                              disabled={true}
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label htmlFor="featuredEvent" className="font-medium text-sm leading-6 text-slate-500">
                            Featured Announcement
                          </label>{' '}
                        </div>
                      </>
                    }

                    {auth.user.stripeSubscriptionId &&
                      <>
                        <div className="flex h-6 items-center">
                            <input
                              type="checkbox"
                              id="featuredEvent"
                              name="featuredEvent"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              ref={register()}
                              checked={isChecked}
                              onChange={checkHandler}
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label htmlFor="featuredEvent" className="font-medium text-sm leading-6 text-slate-800">
                            Featured Announcement
                          </label>{' '}
                        </div>
                      </>
                    }
                  </div>
                  
                  {isChecked &&
                    <>
                      <p className="mt-4 font-medium text-sm leading-6 text-slate-800">
                        Image <span className="text-xs text-slate-500">(Maximum size 672px x 176px)</span>
                      </p>
                      {itemData && itemData.imageURL && !eventImage &&
                        <div className="relative">
                          <div
                            style={{ backgroundImage: `url(${itemData && itemData.imageURL})` }}
                            className="h-40 w-full rounded-lg bg-gray-300 bg-cover bg-center"
                          />
                          <Button
                            type="button"
                            className="absolute top-2 right-2 px-0 py-0 m-0 bg-transparent rounded-sm border-0 hover:bg-transparent"
                            onClick={(e) => { handleRemoveImage(itemData.imageURL) }}
                          >
                            <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                          </Button>
                        </div>
                      }
                      {eventImage && 
                        <div
                          style={{ backgroundImage: `url(${preview})` }}
                          className="h-40 w-full rounded-lg bg-gray-300 bg-cover bg-center"
                        />
                      }

                      <input
                        type="file"
                        id="imageURL"
                        name="imageURL"
                        className="w-full rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                        onChange={(e) => { setEventImage(e.target.files[0]) }}
                        accept="image/jpeg, image/png"
                      />
                    </>
                  }

                  {!isChecked &&
                    <p className="text-xs text-slate-500">
							        Featured announcements appear at the top of the announcements list and can include an image
						        </p>
                  }
                  
                  <TextField
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Title"
                    label="Title"
                    defaultValue={ itemData && itemData.name }
                    error={errors.name}
                    inputRef={register({
                      required: "Please enter a title",
                    })}
                  />

                  <TextField
                    type="text"
                    id="subtitle"
                    name="subtitle"
                    placeholder="Subtitle"
                    label="Subtitle"
                    defaultValue={ itemData && itemData.subtitle }
                    error={errors.subtitle}
                    inputRef={register({})}
                  />

                  <TextField
                    type="textarea"
                    id="description"
                    name="description"
                    placeholder="Description"
                    label="Description"
                    defaultValue={ itemData && itemData.description }
                    error={errors.description}
                    inputRef={register({})}
                  />

                  <div className="grid grid-cols-2 gap-4">
                    <TextField
                      type="text"
                      id="url"
                      name="url"
                      placeholder="Button URL"
                      label="Button URL"
                      defaultValue={ itemData && itemData.url }
                      error={errors.url}
                      inputRef={register({})}
                    />

                    <TextField
                      type="text"
                      id="urlText"
                      name="urlText"
                      placeholder="Button Text"
                      label="Button Text"
                      defaultValue={ itemData && itemData.urlText }
                      error={errors.urlText}
                      inputRef={register()}
                    />
                  </div>

                  <div className="space-x-2 flex items-stretch">
                    <Button
                      size="md"
                      variant="simple"
                      onClick={() => props.onDone()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="md"
                      disabled={pending}
                      isBlock={true}
                      className="w-20 bg-indigo-500"
                    >
                      {!pending && <>Save</>}

                      {pending && <LoadingIcon className="w-5" />}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default EditItemModal;
