import React, { useEffect, useState } from "react";
import { useItemsByOwner } from "./../util/db";
import ChurchEventPrimary from "./ChurchEventPrimary";
import ChurchEventSecondary from "./ChurchEventSecondary";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function ChurchEventsSection(props) {
    const {
        data: items,
        status: itemsStatus,
    } = useItemsByOwner(props.owner, "events");
    const itemsAreEmpty = !items || items.length === 0;

    const [eventsPrimary, setEventsPrimary] = useState(items);
    const [eventsSecondary, setEventsSecondary] = useState(items);

    useEffect(() => {
        if (itemsStatus !== "loading" && items && items.length > 0) {
            setEventsPrimary(
                items.filter(items => items.featuredEvent === true)
            );
            setEventsSecondary(
                items.filter(items => items.featuredEvent !== true)
            )
        }
    }, [items, itemsStatus]);

  return (
    <>
    {itemsStatus === "loading" && <>Loading...</>}

    { itemsStatus !== "loading" && itemsAreEmpty && (
        <div className="my-20">
            <h3 className={classNames(props.fontFamily, props.textColor, 'text-h3-modern uppercase text-center')}>
                No announcements yet
            </h3>
        </div>
    )}

    {itemsStatus !== "loading" && items && items.length > 0 && (
        <section className={classNames(props.bgColor, 'relative px-4 py-14')}>
            <div className="max-w-2xl mx-auto">

                {eventsPrimary && eventsPrimary.map((event, index) => (
                    <ChurchEventPrimary
                        key={index}
                        name={event.name}
                        imageURL={event.imageURL}
                        subtitle={event.subtitle}
                        description={event.description}
                        url={event.url}
                        urlText={event.urlText}
                        textColor={props.textColor}
                        textColorAlt={props.textColorAlt}
                        fontFamily={props.fontFamily}
                    />
                ))}

                {eventsSecondary && eventsSecondary.length > 0 && (
                    <>
                        <h3 className={classNames(props.fontFamily, props.textColor, 'mb-4 text-h3-modern uppercase text-center')}>
                            Other Announcements
                        </h3>
                        <div className={classNames(props.bgColorAlt, 'w-9 h-0.5 mx-auto mb-2')}></div>
                    </>
                )}

                {eventsSecondary && eventsSecondary.map((event, index) => (
                    <ChurchEventSecondary
                        key={index}
                        name={event.name}
                        imageURL={event.imageURL}
                        subtitle={event.subtitle}
                        description={event.description}
                        url={event.url}
                        urlText={event.urlText}
                        textColor={props.textColor}
                        textColorAlt={props.textColorAlt}
                        fontFamily={props.fontFamily}
                    />
                ))}

            </div>
        </section>
    )}
    </>
  )
}

export default ChurchEventsSection;
