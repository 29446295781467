import React from "react";

function TextField(props) {
  const {
    error,
    type = "text",
    size = "md",
    label,
    className,
    inputRef,
    ...inputProps
  } = props;

  const classes = {
    base: "block w-full rounded-md border-0 bg-white/5 py-1.5 text-slate-800 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 text-sm leading-6",
    size: {
      sm: "py-2 leading-5",
      md: "px-4 py-3 leading-6",
      lg: "",
    },
  };

  return (
    <div className={"w-full" + (className ? ` ${className}` : "")}>
      {label && (
        <label className="block mb-1 font-medium text-sm text-slate-800" htmlFor={props.id}>
          {label}
        </label>
      )}

      {type === "textarea" && (
        <textarea
          className={`${classes.base} ${classes.size[size]}`}
          ref={inputRef}
          {...inputProps}
        />
      )}

      {type === "permalink" && (
        <div className="mt-2 flex rounded-md shadow-sm">
          <span className="inline-flex items-center w-60 bg-slate-100 rounded-l-md border border-r-0 border-gray-300 px-3 text-slate-500 text-sm leading-6 text-nowrap">
            https://churchlink.page/
          </span>
          <input
            className={`${classes.base} ${classes.size[size]} rounded-l-none shadow-none`}
            type={type}
            ref={inputRef}
            {...inputProps}
          />
        </div>
      )}

      {type !== "textarea" && type !== "permalink" && (
        <input
          className={`${classes.base} ${classes.size[size]}`}
          type={type}
          ref={inputRef}
          {...inputProps}
        />
      )}

      {error && (
        <p className="text-sm text-left text-red-600 mt-1">{error.message}</p>
      )}
    </div>
  );
}

export default TextField;
