import React, { useState } from "react";
import { PlusIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import FormAlert from "./FormAlert";
import EditItemModal from "./EditItemModal";
import Button from "./Button";
import { deleteItem, useItemsByOwner } from "./../util/db";
// import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useAuth } from "./../util/auth";

function DashboardSectionEvents() {
    const auth = useAuth();

    const {
        data: items,
        status: itemsStatus,
        error: itemsError,
    } = useItemsByOwner(auth.user.uid, "events");

    const [creatingItem, setCreatingItem] = useState(false);
    const [updatingItemId, setUpdatingItemId] = useState(null);
    const itemsAreEmpty = !items || items.length === 0;

    const handleUpdate = (variable) => (event) => {
        event.preventDefault();
        setUpdatingItemId(variable);
    }

    const handleDelete = (variable) => (event) => {
        event.preventDefault();
        deleteItem(variable);
    }

    return (
    <>
    {itemsStatus === "loading" && <>Loading...</>}

    {itemsStatus !== "loading" && itemsAreEmpty && (
        <div className="text-center">
            <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
            >
                <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">No announcements</h3>
            <p className="mt-1 text-sm text-gray-900">Get started by adding an announcement</p>
            <div className="mt-6">
                <Button
                type="button"
                size="md"
                className="inline-flex items-center bg-indigo-500"
                onClick={() => setCreatingItem(true)}
                >
                <PlusIcon className="inline -ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                    New Announcement
                </Button>
            </div>
        </div>
    )}

    {itemsStatus !== "loading" && items && items.length > 0 && (
            <div className="mx-auto max-w-7xl">
                <div className="-mt-2 md:-mt-8 lg:-mt-12">

                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h2 className="text-2xl text-slate-800">
                                    Announcements
                                </h2>
                                <p className="mt-1 text-sm leading-6 text-slate-500">
                                    Compose and share impactful updates, events, and news to keep your church engaged
                                </p>
                            </div>
                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                <Button
                                    type="button"
                                    size="sm"
                                    className="bg-indigo-500"
                                    onClick={() => setCreatingItem(true)}
                                >
                                    Add announcement
                                </Button>
                            </div>
                        </div>

                        {itemsError && (
                            <div className="mb-4">
                                <FormAlert type="error" message={itemsError.message} />
                            </div>
                        )}

                        <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div className="min-w-full divide-y divide-gray-300">
                                        <div className="grid grid-cols-10">
                                            <div className="col-span-8 pl-4 sm:pl-0 pr-3 py-3.5 text-left text-sm font-semibold text-slate-800">
                                                Details
                                            </div>
                                            <div className="hidden sm:block px-3 py-3.5 text-left text-sm font-semibold text-slate-800 text-center">
                                                Featured
                                            </div>
                                            <div className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                                <span className="sr-only">Edit</span>
                                                <span className="sr-only">Delete</span>
                                            </div>
                                        </div>
                                        <div className="divide-y divide-slate-200">


                                            {items.map((event, index) => (
                                                <div key={ index } className="grid grid-cols-10">
                                                    <div className="col-span-6 whitespace pl-4 sm:pl-0 pr-3 py-4 text-sm text-slate-800">
                                                        <span className="text-lg text-gray-900">{event.name}</span>
                                                        <br />
                                                        {event.subtitle}
                                                        <br />
                                                        <div className="max-w-lg mt-2">
                                                            {event.description}
                                                        </div>
                                                    </div>
                                                    <div className="col-span-2 hidden md:block whitespace-nowrap py-4 pl-4 pr-2 text-sm font-medium text-slate-800 sm:pl-0">
                                                        {event.featuredEvent && event.imageURL &&
                                                            <img
                                                                src={event.imageURL}
                                                                alt={event.name}
                                                                className="h-24 w-48 flex-none rounded-lg bg-gray-300 object-cover"
                                                            />
                                                        }
                                                    </div>
                                                    <div className="hidden sm:block whitespace-nowrap px-3 py-4 text-sm text-lime-500 text-center">
                                                        {event.featuredEvent ? 
                                                            <CheckCircleIcon className="inline -ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                                                        : "" }
                                                    </div>
                                                    <div className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                        <a href="#!" className="pr-4 text-indigo-400 hover:text-indigo-300" onClick={handleUpdate(event.id)}>
                                                            Edit<span className="sr-only">, {event.name}</span>
                                                        </a>
                                                        <a href="#!" className="text-indigo-400 hover:text-indigo-300" onClick={handleDelete(event.id)}>
                                                            Delete<span className="sr-only">, {event.name}</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            ))}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        )}

        {creatingItem && (
            <EditItemModal
                onDone={() => setCreatingItem(false)}
            />
        )}
    
        {updatingItemId && (
            <EditItemModal
                id={updatingItemId}
                onDone={() => setUpdatingItemId(null)}
            />
        )}
    </>
  )
}

export default DashboardSectionEvents;
