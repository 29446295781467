import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon"
import { XCircleIcon } from '@heroicons/react/20/solid';
import { useAuth } from "../util/auth";
import { useOrganizationByOwner, updateOrganization, uploadImage, deleteImage } from "../util/db";

function DashboardOrganizationInfo() {
  	const auth = useAuth();
  	const [pending, setPending] = useState(false);
  	const [formAlert, setFormAlert] = useState(null);
  	const [eventImage, setEventImage] = useState(null);
  	const { register, handleSubmit, errors } = useForm();

  	const {
    	data: organization,
  	} = useOrganizationByOwner(auth.user.uid, "organization");

	function handleRemoveImage(file) {
		deleteImage(file).then(() => {
			const query = updateOrganization(organization[0].id, {imageURL: ""});

			query
			.then(() => {
				setPreview("");
			})
			.catch((error) => {
				setFormAlert({
					type: "error",
					message: error.message,
				});
			});
		});
	}

    const [preview, setPreview] = useState();

    useEffect(() => {
        if (!eventImage) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(eventImage)
        setPreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [eventImage]);

	// SOCIALS
	const [socials, setSocials] = useState([{ url: '', platform: '' }]);

	const handleAddSocial = () => {
		setSocials([...socials, { url: '', platform: '' }]);
	};

	const handleSocialChange = (index, field, value) => {
		const newSocials = [...socials];
		newSocials[index][field] = value;
		setSocials(newSocials);
	};

	const handleRemoveSocial = index => {
		const newSocials = [...socials];
		newSocials.splice(index, 1);
		setSocials(newSocials);
	};

	const addedSocials = useRef(false);
	useEffect(() => {
        if (organization && organization[0] && !addedSocials.current) {
			if(organization[0].socials) {
				setSocials(organization[0].socials);
				addedSocials.current = true;
			}
        }
    }, [organization]);

	// QUCIKLINKS
	const [sections, setSections] = useState([{ url: '', btn: '' }]);

	const handleAddSection = () => {
		setSections([...sections, { url: '', btn: '' }]);
	};

	const handleChange = (index, field, value) => {
		const newSections = [...sections];
		newSections[index][field] = value;
		setSections(newSections);
	};

	const handleRemoveSection = index => {
		const newSections = [...sections];
		newSections.splice(index, 1);
		setSections(newSections);
	};

	const addedLinks = useRef(false);
	useEffect(() => {
        if (organization && organization[0] && !addedLinks.current) {
			if(organization[0].quickLinks) {
				setSections(organization[0].quickLinks);
				addedLinks.current = true;
			}
        }
    }, [organization]);
  
  	const onSubmit = (data) => {
    	setPending(true);

    	uploadImage(eventImage).then((url) => {

      	const formData = url
      		? {...data, imageURL: url, quickLinks: sections, socials: socials}
      		: {...data, quickLinks: sections, socials: socials};

      	const query = updateOrganization(organization[0].id, formData);

      	query
        	.then(() => {
          		setPending(false);
        	})
        	.catch((error) => {
          		setPending(false);
          		setFormAlert({
            		type: "error",
            		message: "Username already in use",
          		});
        	});

    	}).catch((error) => {
      		console.log(error);
    	});
  	};

  	return (
    	<>
			{ organization && (
				<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 sm:px-6 lg:px-8">
					<div className="-mt-2 md:-mt-8 lg:-mt-12">
						<h2 className="text-2xl text-slate-800">
							Church Info
						</h2>
						<p className="mt-1 text-sm leading-6 text-slate-500">
							We'll build your ChurchLink with this information
						</p>

						{formAlert && (
							<div className="mb-4">
								<FormAlert
									type={formAlert.type}
									message={formAlert.message}
								/>
							</div>
						)}
					</div>

					<form className="md:col-span-2 space-y-4" onSubmit={handleSubmit(onSubmit)}>
						
						<label className="block mb-2 font-medium text-sm text-slate-800" htmlFor="permalink">
							My ChurchLink URL
						</label>
						<TextField
							label=""
							type="permalink"
							id="permalink"
							name="permalink"
							placeholder="permalink"
							defaultValue={organization[0] && organization[0].permalink}
							error={errors.permalink}
							inputRef={register({
								required: "Please enter a permalink",
								pattern: {
									value: /^[A-Za-z][A-Za-z0-9_]{4,29}$/i,
									message: "Permalinks should start with a letter and may include A-Z, a-z, 1-9 and _"
								},
								minLength: {
									value: 5,
									message: "Permalinks must be at least 5 characters"
								},
								maxLength: {
									value: 30,
									message: "Permalinks can't exceed 30 characters"
								}
							})}
						/>

						<div>
							<div className="h-8"></div>
							<h2 className="text-2xl text-slate-800">
								About
							</h2>
							<div className="h-2"></div>
						</div>

						<div className="grid md:grid-cols-2 gap-4">
							<div className="col-span-full">
								<label htmlFor="imageURL" className="block text-sm leading-6 text-slate-800">
									Logo
								</label>
							</div>
							<div className="relative">
								{organization[0] && organization[0].imageURL && !eventImage && 
									<>
										<div
											style={{ backgroundImage: `url(${organization[0].imageURL})` }}
											className="h-40 w-full mb-4 rounded-lg bg-contain bg-center bg-no-repeat bg-slate-100"
										/>
										<Button
											type="button"
											className="absolute top-2 right-2 px-0 py-0 m-0 bg-transparent rounded-sm border-0 hover:bg-transparent"
											onClick={(e) => { handleRemoveImage(organization[0].imageURL) }}
										>
											<XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
										</Button>
									</>
								}
								{eventImage && 
									<div
										style={{ backgroundImage: `url(${preview})` }}
										className="h-40 w-full mb-4 rounded-lg bg-contain bg-center bg-no-repeat bg-slate-100"
									/>
								}
								<p className="text-xs text-slate-500">
									Maximum size 256px x 64px
								</p>
							</div>
							<div>
								<input
									type="file"
									id="imageURL"
									name="imageURL"
									className="w-full rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-slate-500 shadow-sm hover:bg-white/20"
									onChange={(e) => { setEventImage(e.target.files[0]) }}
									accept="image/jpeg, image/png"
								/>
							</div>
						</div>
						
						<div className="grid gap-4">
							<TextField
								label="Name"
								type="text"
								id="name"
								name="name"
								placeholder="Title"
								defaultValue={organization[0] && organization[0].name}
								error={errors.name}
								inputRef={register({
									required: "Please enter a name",
								})}
							/>
						</div>
					
						<TextField
							label="About"
							type="textarea"
							id="about"
							name="about"
							placeholder="About"
							defaultValue={organization[0] && organization[0].about}
							error={errors.about}
							inputRef={register({})}
						/>
						
						<div>
							<div className="h-8"></div>
							<h2 className="text-2xl text-slate-800">
								Contact
							</h2>
							<div className="h-2"></div>
						</div>
						
						<div className="grid md:grid-cols-2 gap-4">
							<TextField
								label="Email"
								type="text"
								id="email"
								name="email"
								placeholder="info@example.com"
								defaultValue={organization[0] && organization[0].email}
								error={errors.email}
								inputRef={register({
									pattern: {
										value: /\S+@\S+\.\S+/,
										message: "Please enter a valid email address"
								  },
								})}
							/>

							<TextField
								label="Phone"
								type="text"
								id="phone"
								name="phone"
								placeholder="123-456-7890"
								defaultValue={organization[0] && organization[0].phone}
								error={errors.phone}
								inputRef={register({
									pattern: {
										// eslint-disable-next-line
										value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
										message: "Please enter a valid phone number"
									}
								})}
							/>
						</div>

						<div className="grid md:grid-cols-2 gap-4">
							<TextField
								label="Street"
								type="text"
								id="street"
								name="street"
								placeholder="Street"
								defaultValue={organization[0] && organization[0].street}
								error={errors.street}
								inputRef={register({})}
							/>

						
							<TextField
								label="City"
								type="text"
								id="city"
								name="city"
								placeholder="City"
								defaultValue={organization[0] && organization[0].city}
								error={errors.city}
								inputRef={register({})}
							/>

							<TextField
								label="State"
								type="text"
								id="state"
								name="state"
								placeholder="State"
								defaultValue={organization[0] && organization[0].state}
								error={errors.state}
								inputRef={register({})}
							/>

							<TextField
								label="Zip"
								type="text"
								id="zip"
								name="zip"
								placeholder="Zip"
								defaultValue={organization[0] && organization[0].zip}
								error={errors.zip}
								inputRef={register({})}
							/>
						</div>

						<div>
							<div className="h-8"></div>
							<h2 className="text-2xl text-slate-800">
								Socials
							</h2>
							<div className="h-2"></div>
						</div>

						<div>
							{socials.map((social, index) => (
								<div key={index} className="flex mb-4">
									<div className="grid w-full md:grid-cols-2 gap-4">
										<div>
											<label className="block mb-1 font-medium text-sm text-slate-800">
												Social Platform
        									</label>
											<select
        										name={`socialPlatform_${index}`}
        										className="block w-full rounded-md border-0 py-3 pl-3 pr-10 text-slate-800 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 text-sm leading-6"
												value={social.platform}
												onChange={e => handleSocialChange(index, 'platform', e.target.value)}
      										>
												<option>Choose a platform</option>
        										<option value="facebook">Facebook</option>
        										<option value="instagram">Instagram</option>
        										<option value="vimeo">Vimeo</option>
												<option value="x">X (Twitter)</option>
												<option value="youtube">Youtube</option>
      										</select>
										</div>
										<TextField
											name={`socialURL_${index}`}
											value={social.url}
											label="Social URL"
											onChange={e => handleSocialChange(index, 'url', e.target.value)}
											placeholder="https://example.com/"
										/>
									</div>
									<div className="relative w-10 mt-6">
										<Button
											type="button"
											className="absolute top-1/2 px-0 py-0 m-0 bg-transparent rounded-sm border-0 hover:bg-transparent transform -translate-y-1/2"
											onClick={() => handleRemoveSocial(index)}
										>
											<XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
										</Button>
									</div>
								</div>
							))}
							<Button onClick={handleAddSocial} className="px-2 py-1 text-sm" size="small">New Social</Button>
						</div>

						<div>
							<div className="h-8"></div>
							<h2 className="text-2xl text-slate-800">
								QuickLinks
							</h2>
							<div className="h-2"></div>

						</div>
						
						<div>
							{sections.map((section, index) => (
								<div key={index} className="flex mb-4">
									<div className="grid w-full md:grid-cols-2 gap-4">
										<TextField
											name={`quicklinkBTN_${index}`}
											value={section.btn}
											label="Button Text"
											onChange={e => handleChange(index, 'btn', e.target.value)}
											placeholder="Learn more"
										/>
										<TextField
											name={`quicklinkURL_${index}`}
											value={section.url}
											label="Button URL"
											onChange={e => handleChange(index, 'url', e.target.value)}
											placeholder="https://example.com/"
										/>
									</div>
									<div className="relative w-10 mt-6">
										<Button
											type="button"
											className="absolute top-1/2 px-0 py-0 m-0 bg-transparent rounded-sm border-0 hover:bg-transparent transform -translate-y-1/2"
											onClick={() => handleRemoveSection(index)}
										>
											<XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
										</Button>
									</div>
								</div>
							))}
							<Button onClick={handleAddSection} className="px-2 py-1 text-sm" size="small">New QuickLink</Button>
						</div>

						<div className="pt-4 space-x-4 flex items-stretch">
							<Button
								type="submit"
								size="md"
								disabled={pending}
								isBlock={true}
								className="w-20 bg-indigo-500"
							>
								{!pending && <>Update</>}

								{pending && <LoadingIcon className="w-5" />}
							</Button>
						</div>

					</form>

				</div>
			)}
    	</>
  	);
}

export default DashboardOrganizationInfo;
