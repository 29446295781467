import React, { useState, useEffect } from "react";
import { RadioGroup } from '@headlessui/react'
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useAuth } from "../util/auth";
import { useOrganizationByOwner, updateOrganization } from "../util/db";

const themeModes = [
	{ id: 1, title: 'dark', bgColor: 'bg-slate-600', textColor: 'text-slate-100' },
	{ id: 2, title: 'light', bgColor: 'bg-slate-100', textColor: 'text-slate-600' },
]

const typeStyles = [
	{ id: 1, title: 'Sans-serif', font: 'font-sans' },
	{ id: 2, title: 'Serif', font: 'font-serif' },
]

const colors = [
	{ name: 'basic', bgColor: 'bg-basic', selectedColor: 'ring-basic' },
	{ name: 'bright', bgColor: 'bg-bright', selectedColor: 'ring-bright' },
	{ name: 'denim', bgColor: 'bg-denim', selectedColor: 'ring-denim' },
	{ name: 'eggplant', bgColor: 'bg-eggplant', selectedColor: 'ring-eggplant' },
	{ name: 'seaglass', bgColor: 'bg-seaglass', selectedColor: 'ring-seaglass' },
	{ name: 'terracotta', bgColor: 'bg-terracotta', selectedColor: 'ring-terracotta' },
	{ name: 'wine', bgColor: 'bg-wine', selectedColor: 'ring-wine' },
]

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

function DashboardSectionDesign() {
  	const auth = useAuth();
  	const [pending, setPending] = useState(false);
  	const [formAlert, setFormAlert] = useState(null);
  	const { register, handleSubmit } = useForm();

  	const {
    	data: organization,
  	} = useOrganizationByOwner(auth.user.uid, "organization");
  
  	const onSubmit = (data) => {
    	setPending(true);
		const formData = {...data};

      	const query = updateOrganization(organization[0].id, formData);

      	query.then(() => {
			setPending(false);
		})
		.catch((error) => {
			// Hide pending indicator
			setPending(false);
			// Show error alert message
			setFormAlert({
				type: "error",
				message: error.message,
			});
		});

  	};

	const [selectedThemeModes, setSelectedThemeModes] = useState(themeModes[0]);
	const [selectedTypeStyles, setSelectedTypeStyles] = useState(typeStyles[0]);
	const [selectedColor, setSelectedColor] = useState(colors[0]);

	useEffect(() => {
		if(organization && organization[0]) {
			setSelectedThemeModes(organization[0].themeLight);
			setSelectedTypeStyles(organization[0].themeFont);
			setSelectedColor(organization[0].themeColor);
		}
	}, [organization]);

  	return (
    	<>
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 sm:px-6 lg:px-8">
				<div className="-mt-2 md:-mt-8 lg:-mt-12">
					<h2 className="text-2xl text-slate-800">
						Customize
					</h2>
					<p className="mt-1 text-sm leading-6 text-slate-500">
						We'll build your ChurchLink with this information
					</p>

					{formAlert && (
						<div className="mb-4">
							<FormAlert
								type={formAlert.type}
								message={formAlert.message}
							/>
						</div>
					)}
				</div>
				
				<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
					<div className="lg:col-span-2">
						<form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>

							<RadioGroup value={selectedThemeModes} onChange={setSelectedThemeModes}>
								<RadioGroup.Label className="block text-sm text-slate-800">
									Appearance
								</RadioGroup.Label>

								<div className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
									{themeModes.map((themeMode) => (
									<RadioGroup.Option
										key={themeMode.id}
										value={themeMode.title}
										id={themeMode.id}
										className={({ active }) =>
										classNames(
											themeMode.bgColor,
											themeMode.textColor,
											active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
											'relative flex cursor-pointer rounded-lg border p-4 shadow-sm focus:outline-none'
										)
										}
									>
										{({ checked, active }) => (
										<>
											<input className="hidden" type="radio" name="themeLight" ref={register({})} value={themeMode.title} checked={checked} readOnly />
											<span className="flex flex-1">
												<span className="flex flex-col">
													<RadioGroup.Label as="span" className="block text-sm capitalize">
														{themeMode.title}
													</RadioGroup.Label>
												</span>
												</span>
													<CheckCircleIcon
													className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
													aria-hidden="true"
												/>
												<span
												className={classNames(
													active ? 'border' : 'border-2',
													checked ? 'border-indigo-600' : 'border-transparent',
													'pointer-events-none absolute -inset-px rounded-lg'
												)}
												aria-hidden="true"
											/>
										</>
										)}
									</RadioGroup.Option>
									))}
								</div>
							</RadioGroup>

							<RadioGroup value={selectedTypeStyles} onChange={setSelectedTypeStyles}>
								<RadioGroup.Label className="block pt-4 text-sm text-slate-800">
									Type Style
								</RadioGroup.Label>

								<div className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
									{typeStyles.map((typeStyle) => (
									<RadioGroup.Option
										key={typeStyle.id}
										value={typeStyle.font}
										className={({ active }) =>
										classNames(
											typeStyle.font,
											active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
											'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
										)
										}
									>
										{({ checked, active }) => (
										<>
											<input className="hidden" type="radio" name="themeFont" ref={register({})} value={typeStyle.font} checked={checked} readOnly />
											<span className="flex flex-1">
												<span className="flex flex-col">
													<RadioGroup.Label as="span" className="block text-sm">
														{typeStyle.title}
													</RadioGroup.Label>
												</span>
												</span>
													<CheckCircleIcon
													className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
													aria-hidden="true"
												/>
												<span
												className={classNames(
													active ? 'border' : 'border-2',
													checked ? 'border-indigo-600' : 'border-transparent',
													'pointer-events-none absolute -inset-px rounded-lg'
												)}
												aria-hidden="true"
											/>
										</>
										)}
									</RadioGroup.Option>
									))}
								</div>
							</RadioGroup>

							<RadioGroup value={selectedColor} onChange={setSelectedColor}>
								<RadioGroup.Label className="block pt-4 text-sm text-slate-800">
									Primary Color
								</RadioGroup.Label>
								<div className="mt-2 grid grid-cols-7 gap-x-2 sm:gap-x-4">
									{colors.map((color) => (
										<RadioGroup.Option
										key={color.name}
										value={color.bgColor}
										className={({ active, checked }) =>
											classNames(
												color.selectedColor,
												active && checked ? 'ring-2' : '',
												!active && checked ? 'ring-2' : '',
												'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-lg p-0.5 focus:outline-none'
											)
										}
										>	
											<input className="hidden" type="radio" name="themeColor" ref={register({})} value={color.bgColor} checked={color.bgColor === selectedColor} readOnly />
											<RadioGroup.Label as="span" className="sr-only">
												{color.name}
											</RadioGroup.Label>
											<span
												aria-hidden="true"
												className={classNames(color.bgColor, 'h-12 w-full rounded-lg border border-black border-opacity-10')}
											/>
										</RadioGroup.Option>
									))}
								</div>
							</RadioGroup>

							<div className="pt-8 space-x-4 flex items-stretch">
								<Button
									type="submit"
									size="md"
									disabled={pending}
									isBlock={true}
									className="w-20 bg-indigo-500"
								>
									{!pending && <>Update</>}

									{pending && <LoadingIcon className="w-5" />}
								</Button>
							</div>

						</form>
					</div>
					<div>
						<p className="mb-2 text-sm text-slate-800">
							Preview
						</p>
						{organization && organization[0] &&
							<>
								<iframe src={'/' + organization[0].permalink } title="Preview" className="w-full h-full"></iframe>
							</>
						}
					</div>
				</div>

			</div>

    	</>
  	);
}

export default DashboardSectionDesign;