import React, { useState, useEffect } from "react";
import Meta from "./../components/Meta";
import { useRouter, Route } from "./../util/router";
import NotFoundPage from "./404";
import { useOrganizationByPermalink } from "../util/db";
import {
  XMarkIcon
} from '@heroicons/react/24/outline';
import ChurchEventsSection from "./../components/ChurchEventsSection";
import SocialIcon from './../images/socialIcon';
import PWAPrompt from 'react-ios-pwa-prompt'

const typeStyles = [
	{ name: 'font-sans', fontFamily: 'font-sans', borderRadiusBtn: 'rounded-full' },
	{ name: 'font-serif', fontFamily: 'font-serif', borderRadiusBtn: 'rounded-lg' },
]

const themeColors = [
	{ name: 'bg-basic-dark', bgColor: 'bg-basic', bgColorAlt: 'bg-basic-light', borderColor: 'border-white', borderColorAlt: 'border-basic-light', textColor: 'text-white', textColorAlt: 'text-basic', textColorEvents: 'text-basic-dark', borderColorEvents: 'border-basic-dark', bgColorEvents: 'bg-basic-light', bgColorAltEvents: 'bg-basic-dark' },
  { name: 'bg-basic-light', bgColor: 'bg-basic-light', bgColorAlt: 'bg-basic', borderColor: 'border-basic', borderColorAlt: 'border-basic', textColor: 'text-basic', textColorAlt: 'text-basic-light', textColorEvents: 'text-basic-dark', borderColorEvents: 'border-basic-dark', bgColorEvents: 'bg-white', bgColorAltEvents: 'bg-basic-dark' },
	{ name: 'bg-bright-dark', bgColor: 'bg-bright', bgColorAlt: 'bg-bright-light', borderColor: 'border-white', borderColorAlt: 'border-bright-light', textColor: 'text-white', textColorAlt: 'text-bright', textColorEvents: 'text-bright-dark', borderColorEvents: 'border-bright-dark', bgColorEvents: 'bg-bright-light', bgColorAltEvents: 'bg-bright-dark' },
  { name: 'bg-bright-light', bgColor: 'bg-bright-light', bgColorAlt: 'bg-bright', borderColor: 'border-bright', borderColorAlt: 'border-bright', textColor: 'text-bright', textColorAlt: 'text-bright-light', textColorEvents: 'text-bright-dark', borderColorEvents: 'border-bright-dark', bgColorEvents: 'bg-white', bgColorAltEvents: 'bg-bright-dark' },
	{ name: 'bg-denim-dark', bgColor: 'bg-denim', bgColorAlt: 'bg-denim-light', borderColor: 'border-white', borderColorAlt: 'border-denim-light', textColor: 'text-white', textColorAlt: 'text-denim', textColorEvents: 'text-denim-dark', borderColorEvents: 'border-denim-dark', bgColorEvents: 'bg-denim-light', bgColorAltEvents: 'bg-denim-dark' },
  { name: 'bg-denim-light', bgColor: 'bg-denim-light', bgColorAlt: 'bg-denim', borderColor: 'border-denim', borderColorAlt: 'border-denim', textColor: 'text-denim', textColorAlt: 'text-denim-light', textColorEvents: 'text-denim-dark', borderColorEvents: 'border-denim-dark', bgColorEvents: 'bg-white', bgColorAltEvents: 'bg-denim-dark' },
	{ name: 'bg-eggplant-dark', bgColor: 'bg-eggplant', bgColorAlt: 'bg-eggplant-light', borderColor: 'border-white', borderColorAlt: 'border-eggplant-light', textColor: 'text-white', textColorAlt: 'text-eggplant', textColorEvents: 'text-eggplant-dark', borderColorEvents: 'border-eggplant-dark', bgColorEvents: 'bg-eggplant-light', bgColorAltEvents: 'bg-eggplant-dark' },
  { name: 'bg-eggplant-light', bgColor: 'bg-eggplant-light', bgColorAlt: 'bg-eggplant', borderColor: 'border-eggplant', borderColorAlt: 'border-eggplant', textColor: 'text-eggplant', textColorAlt: 'text-eggplant-light', textColorEvents: 'text-eggplant-dark', borderColorEvents: 'border-eggplant-dark', bgColorEvents: 'bg-white', bgColorAltEvents: 'bg-eggplant-dark' },
	{ name: 'bg-seaglass-dark', bgColor: 'bg-seaglass', bgColorAlt: 'bg-seaglass-light', borderColor: 'border-white', borderColorAlt: 'border-seaglass-light', textColor: 'text-white', textColorAlt: 'text-seaglass', textColorEvents: 'text-seaglass-dark', borderColorEvents: 'border-seaglass-dark', bgColorEvents: 'bg-seaglass-light', bgColorAltEvents: 'bg-seaglass-dark' },
  { name: 'bg-seaglass-light', bgColor: 'bg-seaglass-light', bgColorAlt: 'bg-seaglass', borderColor: 'border-seaglass', borderColorAlt: 'border-seaglass', textColor: 'text-seaglass', textColorAlt: 'text-seaglass-light', textColorEvents: 'text-seaglass-dark', borderColorEvents: 'border-seaglass-dark', bgColorEvents: 'bg-white', bgColorAltEvents: 'bg-seaglass-dark' },
	{ name: 'bg-terracotta-dark', bgColor: 'bg-terracotta', bgColorAlt: 'bg-terracotta-light', borderColor: 'border-white', borderColorAlt: 'border-terracotta-light', textColor: 'text-white', textColorAlt: 'text-terracotta', textColorEvents: 'text-terracotta-dark', borderColorEvents: 'border-terracotta-dark', bgColorEvents: 'bg-terracotta-light', bgColorAltEvents: 'bg-terracotta-dark' },
  { name: 'bg-terracotta-light', bgColor: 'bg-terracotta-light', bgColorAlt: 'bg-terracotta', borderColor: 'border-terracotta', borderColorAlt: 'border-terracotta', textColor: 'text-terracotta', textColorAlt: 'text-terracotta-light', textColorEvents: 'text-terracotta-dark', borderColorEvents: 'border-terracotta-dark', bgColorEvents: 'bg-white', bgColorAltEvents: 'bg-terracotta-dark' },
	{ name: 'bg-wine-dark', bgColor: 'bg-wine', bgColorAlt: 'bg-wine-light', borderColor: 'border-white', borderColorAlt: 'border-wine-light', textColor: 'text-white', textColorAlt: 'text-wine', textColorEvents: 'text-wine-dark', borderColorEvents: 'border-wine-dark', bgColorEvents: 'bg-wine-light', bgColorAltEvents: 'bg-wine-dark' },
  { name: 'bg-wine-light', bgColor: 'bg-wine-light', bgColorAlt: 'bg-wine', borderColor: 'border-wine', borderColorAlt: 'border-wine', textColor: 'text-wine', textColorAlt: 'text-wine-light', textColorEvents: 'text-wine-dark', borderColorEvents: 'border-wine-dark', bgColorEvents: 'bg-white', bgColorAltEvents: 'bg-wine-dark' },
]

function ChurchPage(props) {
  const router = useRouter();

  const {
    data: organization,
  } = useOrganizationByPermalink(router.query.church);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function findArrayElementByName(array, name) {
    return array.find((element) => {
      return element.name === name;
    })
  }

  const [openModal, setOpenModal] = useState(false);
  const [notFound, setNotFound] = useState(false);
	const [selectedThemeType, setSelectedThemeType] = useState(typeStyles[0]);
	const [selectedThemeColors, setSelectedThemeColors] = useState(themeColors[4]);

  const handleGiving = (variable) => (event) => {
    event.preventDefault();
    setOpenModal(!openModal);
  }

	useEffect(() => {
		if(organization && organization[0]) {

      if (organization[0].themeColor && organization[0].themeLight) {
        var theme = `${organization[0].themeColor}-${organization[0].themeLight}`;
        setSelectedThemeColors(findArrayElementByName(themeColors, theme));
      }
      if (organization[0].themeFont) {
			  setSelectedThemeType(findArrayElementByName(typeStyles, organization[0].themeFont));
      }
		}
	}, [organization]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://js.churchcenter.com/modal/v1";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(!organization || (organization && organization.length === 0)) {
        setNotFound(true);
      }
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [organization]);

  return (
    <>
      {notFound && (
        <Route component={NotFoundPage} />
      )}
      {organization && organization.length > 0 && (
        <>
          <Meta title={organization[0].name} />

          <section className={classNames(selectedThemeColors.bgColor, selectedThemeColors.textColor, 'relative py-12 px-8 text-center')}>
            <div className="max-w-sm mx-auto">

              {organization[0].imageURL
                ? <div className="w-64 h-16 mx-auto mb-4 bg-contain bg-center bg-no-repeat" title={ organization[0].name } style={{ 
                    backgroundImage: `url("${ organization[0].imageURL }")` 
                  }}></div>
                : <h1 className={classNames(selectedThemeType.fontFamily, selectedThemeColors.textColor, 'mb-8 text-h1-modern')}>
                    { organization[0].name }
                  </h1>
              }

              <p className="mb-4 font-sansSecondary text-h6">
                { organization[0].about }
              </p>

              <div className={classNames(selectedThemeColors.bgColorAlt, 'w-9 h-0.5 mx-auto mb-8')}></div>

              <h3 className={classNames(selectedThemeType.fontFamily, selectedThemeColors.textColor, 'text-h3-modern uppercase')}>
                { new Date().toLocaleDateString('en-us', { weekday: 'long' }) }
              </h3>
              <h2 className={classNames(selectedThemeType.fontFamily, selectedThemeColors.textColor, 'mb-8 text-h1-modern')}>
                { new Date().toLocaleDateString('en-us', {  month: 'long', day: 'numeric', year: 'numeric' }) }
              </h2>
              
              <div className="max-w-xs mx-auto">
                {organization[0].planningcenter &&
                  <a href={'https://' + organization[0].planningcenter + ".churchcenteronline.com/giving?open-in-church-center-modal=true" } className={classNames(selectedThemeColors.bgColorAlt, selectedThemeColors.textColorAlt, selectedThemeType.borderRadiusBtn, 'block p-4 mt-4 font-sansSecondary text-h5 text-center shadow-button')}>
                    Give Now
                  </a>
                }
                {organization[0].tithely &&
                  <a href="#!" onClick={handleGiving()} className={classNames(selectedThemeColors.bgColorAlt, selectedThemeColors.textColorAlt, selectedThemeType.borderRadiusBtn, 'block p-4 mt-4 font-sansSecondary text-h5 text-center shadow-button')}>
                    Give Now
                  </a>
                }
              </div>

            </div>
          </section>

          <ChurchEventsSection
            owner={organization && organization[0].owner}
            textColor={selectedThemeColors.textColorEvents}
            borderColor={selectedThemeColors.borderColorEvents}
            bgColor={selectedThemeColors.bgColorEvents}
            bgColorAlt={selectedThemeColors.bgColorAltEvents}
            fontFamily={selectedThemeType.fontFamily}
            borderRadiusBtn={selectedThemeType.borderRadiusBtn}
          />

          {organization[0].email &&
            <section className={classNames(selectedThemeColors.bgColor, selectedThemeColors.textColor, selectedThemeColors.borderColorAlt, 'relative py-12 px-8 text-center border-b')}>
              <div className="max-w-xs mx-auto">
                <h3 className={classNames(selectedThemeType.fontFamily, 'mb-4 text-h3-modern uppercase')}>
                  QuickLinks
                </h3>
              
                <div className={classNames(selectedThemeColors.bgColorAlt, 'w-9 h-0.5 mx-auto mb-8')}></div>

                {organization[0].quickLinks && organization[0].quickLinks[0] && organization[0].quickLinks.map((event, index) => (
                  <>
                    {event.url &&
                      <a href={event.url} target="_blank" rel="noreferrer" className={classNames(selectedThemeColors.bgColorAlt, selectedThemeColors.textColorAlt, selectedThemeType.borderRadiusBtn, 'block p-4 mt-4 font-sansSecondary text-h5 text-center shadow-button')}>
                        {event.btn ? event.btn : "Learn more"}
                      </a>
                    }
                  </>
                ))}

                {organization[0].email &&
                  <a href={'mailto:' + organization[0].email} className={classNames(selectedThemeColors.bgColorAlt, selectedThemeColors.textColorAlt, selectedThemeType.borderRadiusBtn, 'block p-4 mt-4 font-sansSecondary text-h5 text-center shadow-button')}>
                    Connect With Us
                  </a>
                }

              </div>
            </section>
          }

          <section className={classNames(selectedThemeColors.bgColor, selectedThemeColors.textColor, 'relative py-12 px-8 text-center')}>
            <div className="max-w-sm mx-auto">

              {organization[0].imageURL &&
                <div className="w-64 h-16 mx-auto mb-4 bg-contain bg-center bg-no-repeat" title={ organization[0].name } style={{ 
                  backgroundImage: `url("${ organization[0].imageURL }")` 
                }}></div>
              }
              
              <p className="mb-10 font-sansSecondary text-h6">
                {(organization[0].street || organization[0].city || organization[0].state || organization[0].zip) &&
                  <>
                    {( organization[0].street &&
                      <>
                        { organization[0].street }
                      </>
                    )}
                    {( organization[0].city &&
                      <>
                        ,&nbsp;{ organization[0].city }
                      </>
                    )}
                    {( organization[0].state &&
                      <>
                        ,&nbsp;{ organization[0].state }
                      </>
                    )}
                    &nbsp;{ organization[0].zip }<br />
                  </>
                }
                { organization[0].phone }
              </p>
              
              {organization[0].socials && organization[0].socials[0] && organization[0].socials[0].url &&
                <>
                  <h3 className={classNames(selectedThemeType.fontFamily, selectedThemeColors.textColor, 'mb-4 text-h3-modern uppercase')}>
                    Follow Us
                  </h3>
              
                  <div className={classNames(selectedThemeColors.bgColorAlt, 'w-9 h-0.5 mx-auto mb-5')}></div>
                  
                  <div className="mb-20 text-center">

                    {organization[0].socials && organization[0].socials.map((social, index) => (
                      <a href={social.url} className={classNames(selectedThemeColors.textColor, 'inline-block w-6 h-6 mx-3')}>
                        <SocialIcon platform={social.platform} />
                      </a>
                    ))}

                  </div>
                </>
              }

              <p className="font-sansSecondary text-h6">
                Powered by <a className="font-bold" href="https://churchlink.page/">ChurchLink</a>
              </p>
            </div>
          </section>

          {organization[0].tithely && openModal && (
            <div className="fixed grid inset-0 z-90 justify-center align-center items-center">
              <div className={classNames(selectedThemeColors.bgColor, 'absolute inset-0 opacity-60')}></div>
              <div className="relative z-10">
                <div onClick={() => setOpenModal(!openModal)} className="absolute top-0 right-0 p-1 bg-white transform -translate-y-full cursor-pointer">
                  <XMarkIcon className={classNames(props.textColor, 'h-7 w-7')} aria-hidden="true" />
                </div>
                <iframe title="Tithely" src={`https://tithe.ly/give_new/www/#/tithely/give-one-time/${organization[0].tithely}`} width={380} height={560} loading='eager'></iframe>
              </div>
            </div>
          )}

          <PWAPrompt copyTitle={"Access " + organization[0].name + " with one tap"} copyBody={"The ChurchLink shortcut will appear on your home screen and can be organized like any other app"} />
        </>
      )}
    </>
  );
}

export default ChurchPage;
