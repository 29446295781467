import { React, Fragment, useState } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { useAuth } from "./../util/auth";
import { Link } from "./../util/router";
import ViewChurchLinkURL from "./ViewChurchLinkURL";
import {
  Bars3Icon,
  CalendarIcon,
  BuildingLibraryIcon,
  XMarkIcon,
  EyeDropperIcon,
  BoltIcon,
  LockClosedIcon,
  CogIcon,
  CreditCardIcon,
  CubeTransparentIcon,
  ArrowRightOnRectangleIcon
} from '@heroicons/react/24/outline';

function DashboardNavigation(props) {
    const auth = useAuth();

    const nav_acc = [
        { name: 'Church Info', href: '/dashboard/church', icon: BuildingLibraryIcon, current: false },
        { name: 'Announcements', href: '/dashboard/events', icon: CalendarIcon, current: false },
        { name: 'Customize', href: '/dashboard/design', icon: EyeDropperIcon, current: false },
        { name: 'Integrations', href: '/dashboard/integrations', icon: CubeTransparentIcon, current: false },
    ]

    const nav_admin = [
        { name: 'General', href: '/settings/general', icon: CogIcon, current: false },
        { name: 'Password', href: '/settings/password', icon: LockClosedIcon, current: false },
        { name: 'Billing', href: '/settings/billing', icon: CreditCardIcon, current: false },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <>
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50 xl:hidden" onClose={setSidebarOpen}>
            <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
                <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
                >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                    </div>
                    </Transition.Child>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-slate-50 px-6 ring-1 ring-white/10">
                    <nav className="flex flex-1 flex-col">
                        <div className="flex flex-row items-center pt-6 pb-1 text-xs text-slate-400 uppercase leading-4">
                            <div className="mr-4 text-2xl">
                                👋
                            </div>
                            <div>Signed in as <div className="text-slate-500">{auth.user.email}</div></div>
                        </div>
                        <ul className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul className="-mx-2 space-y-1">

                                <li className="pl-2 pt-6 pb-1 text-xs text-slate-500 uppercase">
                                    Account
                                </li>
                                {nav_acc.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className={classNames(
                                            item.current
                                                ? 'bg-slate-200 text-white'
                                                : 'text-slate-900 hover:bg-slate-200',
                                            'group flex gap-x-2 rounded-md p-2 text-sm'
                                            )}
                                        >
                                            <item.icon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                            {item.name}
                                        </a>
                                    </li>
                                ))}

                                <li className="pl-2 pt-6 pb-1 text-xs text-slate-500 uppercase">
                                    Administration
                                </li>
                                {nav_admin.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className={classNames(
                                            item.current
                                                ? 'bg-slate-200 text-white'
                                                : 'text-slate-900 hover:bg-slate-200',
                                            'group flex gap-x-2 rounded-md p-2 text-sm'
                                            )}
                                        >
                                            <item.icon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                                <li key="SignOut">
                                    <a
                                        href="!#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            auth.signout();
                                        }}
                                        className="group flex gap-x-2 rounded-md p-2 text-sm text-slate-900 hover:bg-slate-200"
                                    >
                                        <ArrowRightOnRectangleIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                        Sign out
                                    </a>
                                </li>

                                <li className="pl-2 pt-6 pb-1 text-xs text-slate-500 uppercase">
                                    Other
                                </li>
                                {!auth.user.stripeSubscriptionId && (
                                    <li key="Pricing">
                                        <a
                                            href="/pricing"
                                            className="text-primary hover:bg-slate-200 group flex gap-x-3 rounded-md p-2 text-sm"
                                        >
                                            <BoltIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                            <span className="bg-gradient-to-r from-primary to-secondary inline-block text-transparent bg-clip-text">
                                                Get ChurchLink Pro
                                            </span>
                                        </a>
                                    </li>
                                )}
                                {auth.user.stripeSubscriptionId && (
                                    <li key="Plan">
                                        <div
                                            className="text-primary group flex gap-x-3 rounded-md p-2 text-sm"
                                        >   
                                            <BoltIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                            <span className="bg-gradient-to-r from-primary to-secondary inline-block text-transparent bg-clip-text">
                                                ChurchLink Pro User
                                            </span>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </li>
                        </ul>
                    </nav>
                    </div>
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col bg-slate-50">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 ring-1 ring-white/5">
            <nav className="flex flex-1 flex-col mt-24">
                <div className="flex flex-row items-center pt-6 pb-1 text-xs text-slate-400 uppercase leading-4">
                    <div className="mr-4 text-2xl">
                        👋
                    </div>
                    <div>Signed in as <div className="text-slate-500">{auth.user.email}</div></div>
                </div>
                <ul className="flex flex-1 flex-col gap-y-7">
                    <li>
                        <ul className="-mx-2 space-y-1">

                        <li className="pl-2 pt-6 pb-1 text-xs text-slate-500 uppercase">
                            Account
                        </li>
                        {nav_acc.map((item) => (
                            <li key={item.name}>
                                <a
                                    href={item.href}
                                    className={classNames(
                                    item.current
                                        ? 'bg-slate-200 text-white'
                                        : 'text-slate-900 hover:bg-slate-200',
                                    'group flex gap-x-2 rounded-md p-2 text-sm'
                                    )}
                                >
                                    <item.icon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                    {item.name}
                                </a>
                            </li>
                        ))}

                        <li className="pl-2 pt-6 pb-1 text-xs text-slate-500 uppercase">
                            Administration
                        </li>
                        {nav_admin.map((item) => (
                            <li key={item.name}>
                                <a
                                    href={item.href}
                                    className={classNames(
                                    item.current
                                        ? 'bg-slate-200 text-white'
                                        : 'text-slate-900 hover:bg-slate-200',
                                    'group flex gap-x-2 rounded-md p-2 text-sm'
                                    )}
                                >
                                    <item.icon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                    {item.name}
                                </a>
                            </li>
                        ))}
                        <li key="SignOut">
                            <a
                                href="!#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    auth.signout();
                                }}
                                className="group flex gap-x-2 rounded-md p-2 text-sm text-slate-900 hover:bg-slate-200"
                            >
                                <ArrowRightOnRectangleIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                Sign out
                            </a>
                        </li>

                        <li className="pl-2 pt-6 pb-1 text-xs text-slate-500 uppercase">
                            Other
                        </li>
                        {!auth.user.stripeSubscriptionId && (
                            <li key="Pricing">
                                <a
                                    href="/pricing"
                                    className="text-primary hover:bg-slate-200 group flex gap-x-3 rounded-md p-2 text-sm"
                                >
                                    <BoltIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                    <span className="bg-gradient-to-r from-primary to-secondary inline-block text-transparent bg-clip-text">
                                        Get ChurchLink Pro
                                    </span>
                                </a>
                            </li>
                        )}
                        {auth.user.stripeSubscriptionId && (
                            <li key="Plan">
                                <div
                                    className="text-primary group flex gap-x-3 rounded-md p-2 text-sm"
                                >   
                                    <BoltIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                    <span className="bg-gradient-to-r from-primary to-secondary inline-block text-transparent bg-clip-text">
                                        ChurchLink Pro User
                                    </span>
                                </div>
                            </li>
                        )}
                    </ul>
                </li>
                </ul>
            </nav>

            </div>
        </div>

        <div>
            {/* Sticky search header */}
            <div className="fixed top-0 z-50 flex w-full h-16 shrink-0 items-center gap-x-6 border-b border-white/5 bg-white px-4 shadow-md sm:px-6">
                <button type="button" className="-m-2.5 p-2.5 text-slate-800 xl:hidden" onClick={() => setSidebarOpen(true)}>
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-5 w-5" aria-hidden="true" />
                </button>
                <div>
                    <div className="flex h-16 shrink-0 items-center">
                        <Link
                            to="/dashboard/church"
                            className="inline-flex items-center space-x-2 font-bold text-lg tracking-wide text-blue-600 hover:text-blue-400"
                        >
                            <img className="h-8" alt="ChurchLink" src={require('./../images/logo-small.png')} />
                        </Link>
                    </div>
                </div>
                <div className="ml-auto">
                    <ViewChurchLinkURL />
                </div>
            </div>
        </div>
      </>
    );
}

export default DashboardNavigation;