import React from "react";
import { useAuth } from "../util/auth";
import { useOrganizationByOwner } from "../util/db";
import Button from "./Button";
import {
    ArrowTopRightOnSquareIcon
  } from '@heroicons/react/24/outline';

function ViewChurchLinkURL() {
  	const auth = useAuth();

  	const {
    	data: organization,
  	} = useOrganizationByOwner(auth.user.uid, "organization");

  	return (
    	<>
            { organization && organization[0] && organization[0].permalink && (
                <Button href={'/' + organization[0].permalink } target="_blank" rel="noreferrer" size="sm" className="text-xs text-slate-500 uppercase transition duration-250 hover:text-slate-800">
                    My ChurchLink
                    <ArrowTopRightOnSquareIcon className="inline h-4 w-4 -mt-1 ml-2 shrink-0" aria-hidden="true" />
                </Button>
            )}
    	</>
  	);
}

export default ViewChurchLinkURL;
