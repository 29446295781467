import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function ChurchEventPrimary(props) {

  return (
    <div className="mb-16">
        {props.imageURL && (
            <div className="w-full max-h-44 aspect-[16/9] mb-4 bg-cover bg-center bg-gray-200 rounded-lg" title={ props.name } style={{ 
                backgroundImage: `url("${ props.imageURL }")` 
            }}></div>
        )}
        <h2 className={classNames(props.fontFamily, props.textColor, 'mb-3 text-h1-modern')}>
            {props.name}
        </h2>
        <p className={classNames(props.textColor, 'mb-2 font-sansSecondary text-h5 bold uppercase')}>
            {props.subtitle}
        </p>
        <p className={classNames(props.textColor, 'mb-4 font-sansSecondary text-h4')}>
            {props.description}
        </p>
        {props.url && (
            <a href={props.url} target="_blank" rel="noreferrer" className={classNames(props.textColorAlt, 'mt-8 font-sansSecondary text-h5 bold underline')}>
                {props.urlText ? props.urlText : "Learn More"}
            </a>
        )}
    </div>
  )
}

export default ChurchEventPrimary;
