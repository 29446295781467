import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import FeaturesSection from "./../components/FeaturesSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import CtaSection from "./../components/CtaSection";
import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <Navbar bgColor="bg-white" />
      <HeroSection2
        title="A simple way to stay connected to your people"
        subtitle="ChurchLink is a one-page mobile hub helping you stay connected with each person that enters your church or watches online"
        strapline=""
        size="lg"
        bgColor="bg-blue-900"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
      />
      <FeaturesSection
        title="The easiest to use ministry tool in the non-app space"
        subtitle="Beautiful, professionally designed elements to make your message stand out"
        strapline="Designed For You"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <TestimonialsSection
        title="Customer Testimonials"
        subtitle=""
        strapline="Real Feedback"
        size="md"
        bgColor="bg-seaglass-light"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection
        title={
          <>
            It only takes <span className="text-blue-600">7 minutes</span> to setup
          </>
        }
        subtitle="Replace your bulletin and benefit from lower costs, easier updates and a larger audience that can access important information from anywhere"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <Footer
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        sticky={true}
      />
    </>
  );
}

export default IndexPage;
