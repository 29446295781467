import React from "react";
import { SparklesIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import { Link } from "./../util/router";
import SectionHeader from "./SectionHeader";
import Button from "./Button";

function HeroSection2(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="absolute inset-x-0 bottom-0 text-blue-700 text-opacity-50">
        <svg fill="currentColor" viewBox="0 0 500 150">
          <path d="M-0.84,34.03 C187.63,148.52 327.03,-4.44 499.72,40.95 L500.00,150.00 L0.00,150.00 Z" />
        </svg>
      </div>
      <div className="relative container">
        <div className="text-center">
          <div
            className="inline-block mb-10 text-blue-600"
          >
            <SparklesIcon className="inline-block w-12 h-12" />
          </div>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-center space-y-2 sm:space-y-0 sm:space-x-2 pt-10 pb-16">
          <Button
            component={Link}
            to="/auth/signup"
            size="xl"
            variant="secondary"
            endIcon={
              <ArrowRightIcon className="opacity-70 inline-block w-5 h-5" />
            }
          >
            Try for free
          </Button>

          <Button
            component={Link}
            to="/kcc_nb"
            size="xl"
            variant="primary"
            target="_blank"
            endIcon={
              <ArrowRightIcon className="opacity-70 inline-block w-5 h-5" />
            }
          >
            View Example
          </Button>
        </div>
        <div className="pb-4 md:pb-0">
          <div className="relative mx-5 lg:mx-32">
            <div className="absolute inset-0 rounded-xl bg-blue-600 bg-opacity-20 -m-4 transform rotate-2" />
            <div className="absolute inset-0 rounded-xl bg-blue-600 bg-opacity-25 -m-4 transform -rotate-2" />
            <img
              className="relative rounded-lg mx-auto shadow-lg"
              src={require('./../images/hero-mockup.jpg')}
              alt=""
            />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection2;
