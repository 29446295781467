import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Avatar from "./Avatar";

function TestimonialsSection(props) {
  const testimonials = [
    {
      avatar: require('./../images/quote-gabe.jpg'),
      name: "Gabe deGarmeaux",
      body: "ChurchLink is an easy to use platform that’s allowing us to engage people in a meaningful way. The design is simple, intuitive, and professional, and it’s affordable for a church plant like ours.",
      role: "Pastor, King's Community",
    },
    {
      avatar: require('./../images/quote-andrew.jpg'),
      name: "Andrew Sale",
      body: "It's rare that you find a church communication tool that minimizes the difficulty typically associated with content maintenance, that still provides the flexibility necessary to fit your church's branding, all while being cost-effective for any size congregation. But that's what Churchlink has done. Easy content management (that looks good) for a more-than-reasonable price. 10/10 would recommend! ",
      role: "Communications Director, NEBC",
    },
    {
      avatar: require('./../images/quote-mackenzie.jpg'),
      name: "Mackenzie Hill",
      body: "I can't believe how easy ChurchLink has made it for our people to find out what's going on. I can't recommend it enough.",
      role: "Communications Director, Rooted",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {testimonials.map((testimonial, index) => (
            <div
              className="border rounded-md bg-gray-50 shadow-sm relative"
              key={index}
            >
              <div className="absolute top-0 right-0 text-8xl mt-1 mr-2 text-blue-200 opacity-75 font-serif">
                “
              </div>
              <div className="px-4 pt-14 pb-6 sm:px-6 sm:pb-6 relative">
                <blockquote>
                  <p className="leading-7 mb-5">{testimonial.body}</p>
                  <footer className="flex items-center space-x-4">
                    <Avatar image={testimonial.avatar} size="sm" />
                    <div>
                      <span className="font-semibold text-blue-600">
                        {testimonial.name}
                      </span>
                      <p className="text-gray-500 font-medium text-sm">
                        {testimonial.role}
                      </p>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default TestimonialsSection;
